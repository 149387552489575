import {
	fetchInvoiceDemands,
	parseInvoiceIntoCard,
	getText,
	translateInvoiceTitle,
	capitalizeFirstLetter,
	getCorrectInvoicePeriodDate,
	fetchParsedInvoices,
} from '../../../services';
import { ICustomerInvoice, IInvoiceDemand, IDefaultProps } from '../../../models';
import { Constants } from '../../../data';
import _ from 'lodash';
import { IInvoiceCard } from '../../../components';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

export interface IInvoiceSelectedPageData extends IDefaultProps {
	invoiceId: string;
}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: IInvoiceSelectedPageData) => {
	const { translations, invoiceId, services, desktopView, user } = config;
	const { userData } = user;
	const { GET, token } = services;

	if (translations && GET !== undefined) {
		let invoiceData = await fetchParsedInvoices({ GET, userData, token });

		if (invoiceData.invoices.length > 0) {
			let selectedInvoice = _getSelectedInvoice(invoiceData.invoices, invoiceId);
			let title = '';
			let invoiceCardData: IInvoiceCard | undefined = undefined;

			if (selectedInvoice?.type) {
				let invoiceDemands: IInvoiceDemand[] = [];

				if (selectedInvoice.collectionCase) {
					invoiceDemands = await fetchInvoiceDemands(GET, selectedInvoice.invoiceNumber);
				}

				invoiceCardData = parseInvoiceIntoCard(selectedInvoice, invoiceDemands, translations, desktopView, GET);

				title = translateInvoiceTitle(selectedInvoice.type, translations);
			}

			return {
				...config,
				sub: {
					title,
					back: {
						text: getText('selectedInvoiceBack', translations),
						link: Constants.paths.invoiceHistoryPage,
					},
					subTitle: selectedInvoice ? _getSubTitle(selectedInvoice) : undefined,
					statusToast: undefined,
				},
				invoice: selectedInvoice,
				selectedInvoice: invoiceCardData,
			};
		}
	}
};

// ************************************
// PRIVATE
// ************************************

const _getSubTitle = (invoice: ICustomerInvoice) => {
	let date = getCorrectInvoicePeriodDate(invoice.invoicePeriod);
	return `${capitalizeFirstLetter(format(date, 'MMMM', { locale: nb }))} ${date.getFullYear()}`;
};

const _getSelectedInvoice = (data: ICustomerInvoice[], id: string) => {
	let selected = _.find(data, (invoice: ICustomerInvoice) => {
		return invoice.invoiceNumber === id;
	});

	return selected;
};
