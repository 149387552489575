import { useContext, createContext } from 'react';
import { Theme } from '@fjordkraft/fjordkraft.component.library';
import { SeasonalMode, locale } from '../../models';


interface IApplicationProps {
    activeTheme: Theme;
    setActiveTheme: (theme: Theme) => void;
    activeBrand: string;
    setActiveBrand: (brand: string) => void;
    desktopView: boolean;
    locales: locale;
    setLocales: (locale: locale) => void;
    seasonalMode: SeasonalMode;
}

export const ApplicationContext = createContext<IApplicationProps>({
    activeTheme: 'Light',
    activeBrand: 'brand-fjordkraft',
    setActiveTheme: () => { },
    setActiveBrand: () => { },
    desktopView: true,
    locales: 'nb',
    setLocales: () => { },
    seasonalMode: 'None',
});

export const useApplicationContext = () => useContext(ApplicationContext);
