import React, { useEffect, useMemo, useState } from 'react';
import {
	IComponent,
	ContentGrid,
	IconType,
	StyleGrid,
	IAction,
	BrandColors,
} from '@fjordkraft/fjordkraft.component.library';
import { v4 as uuid4 } from 'uuid';
import { useApplicationContext, useApplicationCoreDataContext } from '../../contexts';
import {
	AsyncRedirectButton,
	BareDropdown,
	HeaderLogoButton,
	IPlankDropdownItem,
	LogoutButton,
	MenuButton,
	PlankDropdown,
} from '../../components';
import { ICustomerInstallation, IHomePage } from '../../models';
import { IsActiveCustomer, getText, isExternalUrl } from '../../services';
import { Constants } from '../../data';
import { MS_BareDropdownTemplate } from '../../Prefabs';
import './Header.scss';

export interface IHeader extends IComponent {
	rootPageData: IHomePage;
	navigating: (path: string) => void;
}

export const Header = (props: IHeader) => {
	// ************************************
	// Properties
	// ************************************

	const { activeBrand, activeTheme, desktopView } = useApplicationContext();
	const { installation, translation } = useApplicationCoreDataContext();
	const { rootPageData, navigating } = props;
	const classPrefix = 'header';

	// ************************************
	// Lifecycle
	// ************************************

	const [activeNavigationElementId, setActiveNavigationElementId] = useState<string>();

	useEffect(() => {
		if (translation?.pageNavigationFocus && activeNavigationElementId !== translation?.pageNavigationFocus) {
			setActiveNavigationElementId(translation.pageNavigationFocus);
		}
	}, [translation]);

	// ************************************
	// Helpers
	// ************************************

	const _allowMenuButton = (data: any, inst?: ICustomerInstallation) => {
		if (!IsActiveCustomer(inst)) {
			if (data.blockId === 'Benefits') {
				return false;
			}
		}

		return true;
	};

	const _getHeaderNavigationItems = (): IPlankDropdownItem[] => {
		let items: IPlankDropdownItem[] = [];

		if (rootPageData.headerNav.length > 0) {
			for (let data of rootPageData.headerNav) {
				items.push({
					value: data,
					action: {
						link: data.url,
					},
					left: {
						title: data.text,
						icon: data.iconType,
					},
				});
			}
		}

		return items;
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderHeaderNavigation = useMemo(() => {
		let nav: any[] = [];

		if (rootPageData.headerNav.length > 0) {
			rootPageData.headerNav.forEach((data: any) => {
				let buttonAction: IAction = {
					link: data.url,
					text: data.text,
					icon: data.iconType,
					active: activeNavigationElementId === data.blockId,
					useRouterLink: !isExternalUrl(data.url),
				};

				if (_allowMenuButton({ ...data, ...buttonAction }, installation)) {
					nav.push(
						<MenuButton
							key={uuid4()}
							action={buttonAction}
							navigating={navigating}
						/>
					);
				}
			});
		}

		return nav;
	}, [activeNavigationElementId, rootPageData, installation]);

	const _renderDesktop = () => {
		return (
			<ContentGrid
				className={classPrefix}
				direction="row"
				alignment="center"
				tagType="header"
			>
				<ContentGrid
					className={`${classPrefix}__left`}
					tagType={'nav'}
					direction="row"
					alignment="center"
					gap={1}
				>
					<HeaderLogoButton
						link={Constants.paths.energyPage ?? '/'}
						image={{
							src: rootPageData.logo ?? '',
							alt: 'minside logo',
						}}
					/>
				</ContentGrid>
				<ContentGrid
					className={`${classPrefix}__center`}
					tagType={'nav'}
					direction="row"
					alignment="center"
					gap={1}
				>
					{_renderHeaderNavigation}
				</ContentGrid>
				<ContentGrid
					className={`${classPrefix}__right`}
					direction="row"
					alignment="center"
					tagType="nav"
					gap={3}
				>
					<AsyncRedirectButton
						text={getText('mobileText', rootPageData)}
						icon={IconType.SmartPhone}
						addition={'Redirect/mobile'}
					/>
					<LogoutButton
						theme={activeTheme}
						brand={activeBrand}
						text={getText('logoutText', rootPageData)}
						icon={IconType.LogoutIcon}
					/>
				</ContentGrid>
			</ContentGrid>
		);
	};

	const _renderMobile = () => {
		if (translation) {
			return (
				<StyleGrid
					className={`${classPrefix}__small-device`}
					direction="row"
					alignment="center-left"
					boxSizing="border-box"
				>
					<ContentGrid
						className={`${classPrefix}__small-device__left`}
						tagType="nav"
						direction="row"
						alignment="center-left"
						boxSizing="border-box"
						scaling={2}
					>
						<PlankDropdown
							id={`${classPrefix}-dropdown-left`}
							theme={activeTheme}
							brand={activeBrand}
							icon={IconType.MenuBurger}
							gridPlacement="bottom-left"
							includeActiveText={false}
							includeActiveItem={true}
							items={_getHeaderNavigationItems()}
							customization={{
								iconColor: BrandColors['primary-shade-light-2'],
								gap: 1,
								gapType: 'px',
							}}
						/>
					</ContentGrid>
					<ContentGrid
						className={`${classPrefix}__small-device__right`}
						tagType="nav"
						direction="row"
						alignment="center-right"
						boxSizing="border-box"
						scaling={2}
					>
						<BareDropdown
							id={`${classPrefix}__header-right-dropdown`}
							className={`${classPrefix}__right-dropdown`}
							icon={IconType.LogoutIcon}
							gridPlacement={'bottom-right'}
							template={MS_BareDropdownTemplate(activeTheme, desktopView)}
						>
							<AsyncRedirectButton
								id={`${classPrefix}__header-right-dropdown`}
								text={getText('mobileText', rootPageData)}
								icon={IconType.SmartPhone}
								addition={'Redirect/mobile'}
								flipped={true}
							/>
							<LogoutButton
								id={`${classPrefix}__header-right-dropdown`}
								brand={activeBrand}
								theme={activeTheme}
								text={getText('logoutText', rootPageData)}
								icon={IconType.LogoutIcon}
								flipped={true}
							/>
						</BareDropdown>
					</ContentGrid>
				</StyleGrid>
			);
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderHeader = useMemo(() => {
		return (
			<>
				{desktopView && _renderDesktop()}
				{!desktopView && _renderMobile()}
			</>
		);
	}, [desktopView, activeBrand, activeTheme, installation, rootPageData, activeNavigationElementId]);

	// ************************************
	// Render
	// ************************************

	return _renderHeader;
};
