import React, { useState, useEffect } from 'react';
import {
    Alignment,
    BaseComponent,
    BrandColors,
    IComponent,
    IComponentTemplate,
    Icon,
    IconType,
    IText,
    StyleGrid,
    StyleLoopLimit,
    Text,
} from '@fjordkraft/fjordkraft.component.library';
import { MS_BareDropdownTemplate, MS_PlankDropdownTemplate, paragraphTextPrefab } from '../../Prefabs';
import classNames from 'classnames';
import { MsButton } from '..';
import { useApplicationContext } from '../../contexts';
import './BareDropdown.scss';

export interface IBareDropdownTemplate {
    dropdown: IComponentTemplate;
    container: IComponentTemplate;
}

export interface IBareDropdown extends Omit<IComponent, 'palette' | 'template' | 'id'> {
    id: string;
    icon?: IconType;
    text?: string;
    gridPlacement: Alignment;
    template?: IBareDropdownTemplate;
    genericDropdownStyle?: boolean;
    customization?: {
        text?: IText;
        gap?: StyleLoopLimit;
        gapType?: 'px' | 'rem';
        iconColor?: BrandColors;
        iconBoxed?: boolean;
    }
}

export const BareDropdown = (props: IBareDropdown) => {
    // ************************************
    // Properties
    // ************************************

    const { desktopView } = useApplicationContext();
    const {
        id,
        theme = 'Light',
        brand,
        className,
        children,
        icon,
        text,
        template = MS_BareDropdownTemplate(theme, desktopView),
        gridPlacement,
        genericDropdownStyle = false,
        customization = {
            text: paragraphTextPrefab(),
            gap: 1,
            gapType: 'rem',
            iconColor: BrandColors["primary-shade-light-2"],
            iconBoxed: false
        }
    } = props;
    const classPrefix = 'bare-dropdown';

    // ************************************
    // Lifecycle
    // ************************************

    const [toggled, setTogggled] = useState<boolean>(false);

    useEffect(() => {
        document.addEventListener('mousedown', _handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', _handleClickOutside);
        };
    }, []);

    // ************************************
    // Helper Functionality
    // ************************************

    const _handleClickOutside = (event: any) => {
        if (event?.target?.id !== id) {
            setTogggled(false);
        }
    };

    const _renderIcon = () => {
        let iconToUse: IconType | undefined;

        if (icon) {
            iconToUse = icon;
        } else if (genericDropdownStyle) {
            iconToUse = toggled ? IconType.ChevronUp : IconType.ChevronDown;
        }

        if (iconToUse) {
            return (
                <Icon
                    className={classNames(`${classPrefix}__main-button__icon`, {
                        [`${classPrefix}__main-button__icon--boxed`]: customization.iconBoxed
                    })}
                    type={iconToUse}
                    width={1.438}
                    height={1.438}
                    color={customization.iconColor}
                />
            );
        }
    }

    // ************************************
    // Template handling
    // ************************************

    const _handleContainerTemplate = (): IComponentTemplate => {
        let base: IComponentTemplate = template.container;

        if (base?.transform?.grid?.gapType && customization.gapType) {
            base.transform.grid.gapType = customization.gapType;

        }

        if (base?.transform?.grid?.gap && customization.gap) {
            base.transform.grid.gap = customization.gap;
        }

        return base;
    }

    // ************************************
    // Render
    // ************************************

    return (
        <StyleGrid
            className={classNames(`${classPrefix}`, {
                [`${className}`]: className,
            })}
            alignment="center"
            direction="column"
            boxSizing='border-box'
            brand={brand}
        >
            <MsButton
                id={id}
                className={`${classPrefix}__main-button`}
                template={template.dropdown}
                action={{
                    onClick: () => {
                        setTogggled(!toggled);
                    },
                }}
            >
                {text && (
                    <Text
                        id={id}
                        className={`${classPrefix}__main-button__text`}
                        {...paragraphTextPrefab()}
                        {...customization.text}
                    >
                        {text}
                    </Text>
                )}
                {_renderIcon()}
            </MsButton>
            {toggled && (
                <BaseComponent
                    id={id}
                    className={classNames(`${classPrefix}__grid`, {
                        [`${classPrefix}__grid--${gridPlacement}`]:
                            gridPlacement,
                    })}
                    template={_handleContainerTemplate()}
                    brand={brand}
                >
                    {children}
                </BaseComponent>
            )}
        </StyleGrid>
    );
};
