export const Constants = {
	uiBrand: process.env.REACT_APP_UI_BRAND,
	uiTheme: 'Light',
	appUrl: process.env.REACT_APP_URL,
	appEnv: process.env.REACT_APP_ENV,
	isLocalHost: process.env.REACT_APP_IS_LOCAL_HOST === 'true',
	mobileBreak: 1040,
	popupFrequencyDays: 90,
	customerEffortScore: {
		minimumTimeOnPage: 5,
		globalMinimumTimeOnPage: 7.5,
		timeBetweenQuestions: 60,
	},
	api: {
		url: process.env.REACT_APP_API_URL,
		version: 'v1',
		brand: process.env.REACT_APP_API_BRAND,
	},
	sanity: {
		projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
		dataSet: process.env.REACT_APP_SANITY_DATASET,
		useCdn: process.env.NODE_ENV === 'production'
	},
	episerver: {
		pageId: process.env.REACT_APP_EPISERVER_ROOT_PAGE_ID,
	},
	paths: {
		energyPage: process.env.REACT_APP_ENERGY_PAGE,
		oldFrontPage: process.env.REACT_APP_OLD_FRONT_PAGE,
		invoicePage: process.env.REACT_APP_INVOICE_PAGE,
		invoiceHistoryPage: process.env.REACT_APP_INVOICE_HISTORY_PAGE,
		messagesPage: process.env.REACT_APP_MESSAGES_PAGE,
		userPage: process.env.REACT_APP_USER_PAGE,
		powerAgreementPage: process.env.REACT_APP_POWER_AGREEMENT_PAGE,
		movePage: process.env.REACT_APP_MOVE_PAGE,
		moveOutPage: process.env.REACT_APP_MOVE_OUT_PAGE,
		benefitsPage: process.env.REACT_APP_BENEFITS_PAGE,
		benefitsIntroPage: process.env.REACT_APP_BENEFITS_INTRO_PAGE,
		kickbackTransactionsPage: process.env.REACT_APP_KICKBACK_TRANSACTIONS_PAGE,
		usePointsPage: process.env.REACT_APP_USE_POINTS_PAGE,
		usePointsInvoicePage: process.env.REACT_APP_USE_POINTS_INVOICE_PAGE,
		usePointsWebshopPage: process.env.REACT_APP_USE_POINTS_WEBSHOP_PAGE,
		myBenefitsPage: process.env.REACT_APP_MY_BENEFITS_PAGE,
		powerPage: process.env.REACT_APP_POWER_PAGE,
		powerCostPage: process.env.REACT_APP_POWER_COST_PAGE,
		powerUsagePage: process.env.REACT_APP_POWER_USAGE_PAGE,
		spotPricePage: process.env.REACT_APP_SPOTPRICE_PAGE,
		invoiceSettings: process.env.REACT_APP_INVOICE_SETTINGS,
		fixedDueDate: process.env.REACT_APP_INVOICE_FIXED_DUE_DATE_PAGE,
		servicesPage: process.env.REACT_APP_SERVICES_PAGE,
		predictablePayment: process.env.REACT_APP_PREDICTABLE_PAYMENT_PAGE,
		predictablePaymentTransactions: process.env.REACT_APP_PREDICTABLE_PAYMENT_TRANSACTIONS,
		userCommunication: process.env.REACT_APP_USER_COMMUNICATIONS,
		onboardingPage: process.env.REACT_APP_ONBOARDING_PAGE,
		guests: process.env.REACT_APP_GUESTS,
		consentPage: process.env.REACT_APP_CONSENT_PAGE,
		reservationPage: process.env.REACT_APP_RESERVED_PAGE
	},
	whitelisted: process.env.REACT_APP_CLOSEDFEATURE_WHITELIST
		? process.env.REACT_APP_CLOSEDFEATURE_WHITELIST.split(',')
		: [],

	epiServerPageNames: {
		consentPage: {
			type: 'HeadlessConsentPageType',
			url: process.env.REACT_APP_CONSENT_PAGE,
			id: 'Samtykke'
		},
		reservationPage: {
			type: 'ReservationPage',
			url: process.env.REACT_APP_CONSENT_PAGE,
			id: 'Reservasjon'
		},
		benefits: {
			type: 'BenefitPages',
			url: process.env.REACT_APP_BENEFITS_PAGE,
			id: 'Fordel',
		},
		energyAgreement: {
			type: 'HeadlessActiveEnergyAgreementPageType',
			url: process.env.REACT_APP_POWER_AGREEMENT_PAGE,
			id: 'Strømavtale',
		},
		consumptionCost: {
			type: 'HeadlessConsumptionCostPageType',
			url: process.env.REACT_APP_POWER_COST_PAGE,
			id: 'Strømkostnad',
		},
		consumptionUse: {
			type: 'HeadlessConsumptionUsePageType',
			url: process.env.REACT_APP_POWER_USAGE_PAGE,
			id: 'Strømforbruk',
		},
		home: {
			type: 'HeadlessHomePageType',
			url: process.env.REACT_APP_ENERGY_PAGE,
			id: 'Forside',
		},
		inbox: {
			type: 'HeadlessInboxPageType',
			url: process.env.REACT_APP_MESSAGES_PAGE,
			id: 'Meldinger',
		},
		invoice: {
			type: 'HeadlessInvoicePageType',
			url: process.env.REACT_APP_INVOICE_PAGE,
			id: 'Faktura',
		},
		move: {
			type: 'HeadlessMovePageType',
			url: process.env.REACT_APP_MOVE_PAGE,
			id: 'Flytting',
		},
		services: {
			type: 'HeadlessServicesPage',
			url: process.env.REACT_APP_SERVICES_PAGE,
			id: 'Tjenester',
		},
		spotPrice: {
			type: 'HeadlessSpotPricePageType',
			url: process.env.REACT_APP_SPOTPRICE_PAGE,
			id: 'Spotpris nå',
		},
		invoiceHistory: {
			type: 'InvoiceHistory',
			url: process.env.REACT_APP_INVOICE_HISTORY_PAGE,
			id: 'Faktura',
		},
		invoiceSelected: {
			type: 'SelectedInvoice',
			url: process.env.REACT_APP_INVOICE_HISTORY_PAGE,
			id: 'Faktura',
		},
		invoiceSettings: {
			type: 'InvoiceSettingsPage',
			url: process.env.REACT_APP_INVOICE_SETTINGS,
			id: 'Faktura: Fakturainnstillinger',
		},
		invoiceFixedDueDate: {
			type: 'HeadlessInvoiceEditDueDatePageType',
			url: process.env.REACT_APP_INVOICE_FIXED_DUE_DATE_PAGE,
			id: 'Faktura: Fastforfall'
		},
		me: {
			type: 'MePage',
			url: process.env.REACT_APP_USER_PAGE,
			id: 'Meg',
		},
		communication: {
			type: 'MyCommunicationSettingsPage',
			url: process.env.REACT_APP_USER_COMMUNICATIONS,
			id: 'Meg: Kommunikasjon',
		},
		guests: {
			type: 'GuestAdminPage',
			url: process.env.REACT_APP_GUESTS,
			id: 'Meg: Gjester',
		},
		notFound: {
			type: 'PageNotFound',
			url: '',
			id: 'Fant ingen side',
		},
		onboardingPage: {
			type: 'HeadlessTutorialPageType',
			url: process.env.REACT_APP_ONBOARDING_PAGE,
			id: 'Veileder',
		},
		accessDeniedPage: {
			type: 'AccessDeniedPage',
			url: '',
			id: 'IngenTilgang',
		},
	},
	features: {
		consumptionUse: 'ConsumptionUse',
		consumptionCost: 'ConsumptionCost',
		spotPrice: 'Spotprice',
		services: 'Services',
		invoices: 'Invoices',
		benefits: 'Benefits',
		move: 'Moving',
		inbox: 'Inbox',
		mySettings: 'MySettings',
		agreement: 'Agreement',
		onboarding: 'Onboarding',
		steddi: 'Steddi',
		trumf: 'Trumf',
		installationStatus: 'installationStatus',
		guestAdmin: 'GuestAdmin',
		consentPage: 'ConsentPage',
		reservationPage: 'ReservedPage'
	},
	services: {
		dynamicInfoDetailsId: 'dynamicDetails',
	},
	meterNumberValidation: {
		minDigits: process.env.REACT_APP_METER_NUMBER_VALIDATION_MIN_DIGITS,
		maxDigits: process.env.REACT_APP_METER_NUMBER_VALIDATION_MAX_DIGITS,
		isMeterIdIfFirstDigitsEqual: process.env.REACT_APP_METER_NUMBER_VALIDATION_IS_METER_ID_IF_FIRST_DIGITS_EQUALS,
	},
	minimumBindingPeriodGiftcardProductId: 1001920,
	keys: {
		consentPopupClosed: 'consentPopupClosed'
	}
} as any;