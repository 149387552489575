import { format } from 'date-fns';
import { IDetailsDescriptionBlock } from '../../../../blocks';
import { ICardPoint, IStatePlank } from '../../../../components';
import { Constants } from '../../../../data';
import {
	IAdditonalAddonService,
	IAddonData,
	ICustomerInstallation,
	ICustomerProductPrice,
	IDataPoint,
	IFuturePrice,
	IProductPrice,
	IServicePage,
	IServicePrices,
	IServiceStatusDescriptions,
	ServiceStatus,
} from '../../../../models';
import {
	ITypedResponse,
	createString,
	getClampedStatusFromInstallation,
	getSpecificAddonStateForInstallation,
	getText,
	getTextV2,
	narrowDownResultStatusForPage,
	narrowDownResultStatusForPlank,
	parseActiveExtraProducts,
	tNumber,
} from '../../../../services';
import { IServiceBasePageData } from '../ServiceBasePageData';
import { BrandColors, getCounterTheme } from '@fjordkraft/fjordkraft.component.library';
import { getPlankPrefab, MS_ButtonTemplate } from '../../../../Prefabs';
import { typedGetRequest } from '../../../../contexts';

// ************************************
// PUBLIC
// ************************************

export const getCoreDetails = async (
	config: IServiceBasePageData,
	status: ServiceStatus
): Promise<IDetailsDescriptionBlock[]> => {
	const { translations, activeTheme, activeBrand, user, addonStates } = config;
	const { installation } = user;

	let dynamicInfoBlock: any = undefined;
	let detailCards: IDetailsDescriptionBlock[] = [];

	if (translations?.serviceDescriptions && translations.serviceDescriptions.length > 0) {
		translations.serviceDescriptions.forEach((statusDesc: IServiceStatusDescriptions) => {
			let desc: IServiceStatusDescriptions | undefined = pointOutStateDescription(statusDesc, status);

			if (desc?.infoBlocks && desc.infoBlocks.length > 0) {
				for (let info of desc.infoBlocks) {
					if (info.blockId === Constants.services.dynamicInfoDetailsId) {
						dynamicInfoBlock = info;
						break;
					}
				}
			}
		});
	}

	let { points, hasCampaign } = await getCoreDetailPoints(config as IGetCoreDetailPoints, dynamicInfoBlock);
	let offerPlanks: IStatePlank[] = await _getCoreDetailsPlanks(config, status);
	let additionalAddonPoints = await getAdditionalAddonServicesPoints(config, status);
	let pointsToUse: ICardPoint[] = points;

	if (additionalAddonPoints && additionalAddonPoints.length > 0) {
		pointsToUse = additionalAddonPoints.concat(pointsToUse);
	}

	if (installation) {
		const activeFromDate = getAddonActiveFutureFromDate(
			installation,
			addonStates,
			translations.productDefinitionId
		);
		if (activeFromDate) {
			pointsToUse.push({
				leftText: getText('activeFrom', translations),
				rightText: activeFromDate,
			});
		}
	}

	if (dynamicInfoBlock) {
		detailCards.push({
			title: dynamicInfoBlock.title,
			description: dynamicInfoBlock.descriptionText,
			cardTheme: hasCampaign ? getCounterTheme(activeTheme) : activeTheme,
			brand: activeBrand,
			points: {
				title: getText('pageTitle', translations),
				points: pointsToUse,
				planks: offerPlanks,
			},
		});
	}

	return detailCards;
};

export const getAdditionalAddonServicesPoints = async (
	config: IServiceBasePageData,
	status: ServiceStatus
): Promise<ICardPoint[] | undefined> => {
	const { translations, user } = config;
	const { installation } = user;

	if (
		translations.serviceAdditionalAddonBlocks &&
		translations.serviceAdditionalAddonBlocks.length > 0 &&
		installation
	) {
		return await _handleAdditionalPoints(config, status);
	}
};

export const getAddonActiveFutureFromDate = (
	installation: ICustomerInstallation,
	addonStates: any,
	productDefinitionId: string
): string | undefined => {
	const addonState = getSpecificAddonStateForInstallation(productDefinitionId, installation?.meterId, addonStates);

	if (addonState?.state == 'ACTIVE_FUTURE' && addonState?.fromDate) {
		return format(new Date(addonState?.fromDate), 'dd.MM.yyyy');
	}
};

const _handleAdditionalPoints = async (config: IServiceBasePageData, status: ServiceStatus): Promise<ICardPoint[]> => {
	const { translations, services, user } = config;
	const { installation } = user;
	const { GETTYPED } = services;

	let points: ICardPoint[] = [];

	if (installation) {
		const activeExtraProductIds = parseActiveExtraProducts(installation, config.translations, config.addonStates);

		for await (let additional of translations.serviceAdditionalAddonBlocks) {
			if (additional.serviceId && additional.serviceId !== 'none') {
				let resp = await GETTYPED<IServicePrices>(
					`Services/prices/${additional.serviceId}/${installation.meterId}`
				);

				if (resp.data && resp.callState === 'success') {
					const subscribedToAdditionalProduct = activeExtraProductIds?.includes(
						parseInt(additional.serviceId)
					);
					const subscribedToPrimaryProduct: boolean = narrowDownResultStatusForPlank(status) === 'ACTIVE';

					const customerPrices: ICardPoint[] | undefined = _getCustomerPricePoints(resp.data, config);
					const productPrices: ICardPoint[] | undefined = _getProductPricePoints(resp.data, config);
					const minimumTermDefinitions: ICardPoint[] | undefined = _getMinimumTermDefinitionPoints(
						resp.data,
						config
					);
					let epiPoints = _getEpiPointsFromAdditionalAddonService(additional);

					if (subscribedToPrimaryProduct) {
						if (subscribedToAdditionalProduct) {
							if (customerPrices) {
								points = points.concat(epiPoints.points);
								points = points.concat(customerPrices);
							}
							if (minimumTermDefinitions) {
								points = points.concat(epiPoints.points);
								points = points.concat(epiPoints.bindingTimePoints);
								points = points.concat(minimumTermDefinitions);
							}
						} else {
							// If we are not subscribed to the additional product,
							// but we ARE subscribed to the primary product,
							// we don't show anything related to the additional.
						}
					} else {
						if (subscribedToAdditionalProduct) {
							if (minimumTermDefinitions) {
								points = points.concat(epiPoints.points);
								points = points.concat(epiPoints.bindingTimePoints);
								points = points.concat(minimumTermDefinitions);
							}
						} else {
							points = points.concat(epiPoints.points);
							points = points.concat(epiPoints.bindingTimePoints);
							if (productPrices) points = points.concat(productPrices);
						}
					}
				} else {
					points.push({
						leftText: getText('noDataFound', translations),
						rightText: '',
						customization: { color: BrandColors['status-shade-light-3'] },
					});
				}
			}
		}
	}

	return points;
};

const _getCustomerPricePoints = (data: IServicePrices, config: IServiceBasePageData): ICardPoint[] | undefined => {
	if (data?.customerPrices && data.customerPrices.length > 0) {
		let points: ICardPoint[] = [];

		for (let value of data.customerPrices) {
			points.push({
				subDetail: true,
				leftText: value.name,
				rightText: `${tNumber(value.vatInclusivePrice, 'no-NO')} ${value.denomination}`,
			});
		}

		return points;
	}
};

const _getProductPricePoints = (data: IServicePrices, config: IServiceBasePageData): ICardPoint[] | undefined => {
	if (data?.productPrices && data.productPrices.length > 0) {
		let points: ICardPoint[] = [];

		for (let value of data.productPrices) {
			points.push({
				subDetail: true,
				leftText: value.name,
				rightText: `${tNumber(value.vatInclusivePrice, 'no-NO')} ${value.denomination}`,
			});
		}

		return points;
	}
};

const _getMinimumTermDefinitionPoints = (
	data: IServicePrices,
	config: IServiceBasePageData
): ICardPoint[] | undefined => {
	if (data?.minimumTermDefinitions && data.minimumTermDefinitions.length > 0) {
		let points: ICardPoint[] = [];

		for (let term of data.minimumTermDefinitions) {
			points.push({
				subDetail: true,
				leftText: getText('breachFee', config.translations),
				rightText: `${tNumber(term.breachFee, 'no-NO')} ${term.breachFeeDenomination}`,
				extraLeftText: `${getText('expiresAt', config.translations)}`,
				extraRightText: format(new Date(term.expiresAt), 'dd.MM.yyyy'),
			});
			const extraBreachFeeInformationText = getTextV2({
				key: 'breachFeeInformation',
				translations: config.translations,
				includeMissing: false,
			});
			if (extraBreachFeeInformationText) {
				points.push({
					subDetail: true,
					extraLeftText: extraBreachFeeInformationText,
				});
			}
		}

		return points;
	}
};

const _getEpiPointsFromAdditionalAddonService = (additional: IAdditonalAddonService) => {
	let points: ICardPoint[] = [];
	let bindingTimePoints: ICardPoint[] = [];

	if (additional.dataPoints && additional.dataPoints.length > 0) {
		additional.dataPoints.forEach((point: IDataPoint) => {
			let rightText: string | undefined;

			if (point.key !== 'bindingTime') {
				switch (point.key) {
					case 'title':
						rightText = undefined;
						break;
					case 'text':
						rightText = `${point.amountSuffix}`;
						break;
					default:
						rightText = `${point.amount} ${point.amountSuffix}`;
				}

				points.push({
					leftText: point.name,
					rightText,
					subDetail: true,
				});
			} else {
				bindingTimePoints.push({
					leftText: point.name,
					rightText: `${point.amount} ${point.amountSuffix}`,
					subDetail: true,
				});
			}
		});
	}

	return { points, bindingTimePoints };
};

// ************************************
// PRIVATE
// ************************************

const _getCoreDetailsPlanks = async (config: IServiceBasePageData, status: ServiceStatus) => {
	const { translations, addonStates } = config;
	let planks: IStatePlank[] = [];

	if (narrowDownResultStatusForPage(status) === 'INACTIVE' && addonStates) {
		if (translations.orderMethodType === 'installation') {
			planks = await _getInstallationOfferPlanks(config);
		}
	}

	return planks;
};

const _getInstallationOfferPlanks = async (config: IServiceBasePageData): Promise<IStatePlank[]> => {
	const { services, translations, user, setInstallation, addonStates = [] } = config;
	const { userData } = user;
	const { GETTYPED } = services;

	let planks: IStatePlank[] = [];

	for (let addon of addonStates) {
		if (addon.id === translations.productDefinitionId) {
			for (let inst of userData.installations) {
				let installationAddonState = narrowDownResultStatusForPage(addon.state[inst.meterId]?.state);
				let resp = await GETTYPED<IServicePrices>(
					`Services/prices/${translations.productDefinitionId}/${inst.meterId}?contentId=${translations.epiContentId}`
				);

				if (installationAddonState && installationAddonState === 'ACTIVE' && resp.data?.campaignEnd) {
					planks.push(
						getPlankPrefab('Action', {
							theme: getCounterTheme(config.activeTheme),
							right: {
								template: MS_ButtonTemplate(config.activeTheme, 'secondary'),
							},
							left: {
								title: getText('plankCampaignAvailableTitle', translations),
								description: inst.address.streetAddress,
							},
							action: {
								text: getText('plankCampaignAvailableValue', translations),
								onClick: () => {
									if (setInstallation) {
										setInstallation(inst);
									}
								},
							},
						})
					);
				}
			}
		}
	}

	return planks;
};

export interface IGetCoreDetailPoints {
	translations: any;
	services: {
		GETTYPED: typedGetRequest;
	};
	user: {
		installation?: ICustomerInstallation;
	};
	addonStates?: IAddonData[];
}

export const getCoreDetailPoints = async (config: IGetCoreDetailPoints, infoBlock: any) => {
	const { translations, services, user } = config;
	const { installation } = user;
	const { GETTYPED } = services;

	let points: ICardPoint[] = [];
	let data: IServicePrices | null = null;
	let resp: ITypedResponse<IServicePrices> = { callState: 'idle', data: null };
	let hasCampaign: boolean = false;

	if (translations.orderMethodType === 'installation' && installation) {
		resp = await GETTYPED<IServicePrices>(
			`Services/prices/${translations.productDefinitionId}/${installation.meterId}?contentId=${translations.epiContentId}`
		);

		points.push({
			id: 'installationAddressPlank',
			leftText: getText('address', translations),
			rightText: installation?.address?.streetAddress ?? '',
		});
	} else if (translations.orderMethodType !== 'installation' && translations.productDefinitionId) {
		resp = await GETTYPED<IServicePrices>(
			`Services/prices/${translations.productDefinitionId}?contentId=${translations.epiContentId}`
		);
	} else {
		points = _getAdditionalPointsFromEpi(infoBlock);
	}

	if (resp.callState === 'success') {
		data = resp.data;

		let campaignEnd: string | null | undefined = data?.campaignEnd;
		hasCampaign = !!campaignEnd;

		points = points.concat(
			_getCostPoints(
				translations,
				_getPriceRightTextParsed({
					config,
					data,
					end: campaignEnd,
					installation,
				})
			)
		);

		points = points.concat(_getAdditionalPointsFromEpi(infoBlock));
	} else if (resp.callState === 'error') {
		points = [
			{
				leftText: getText('noDataFound', translations),
				rightText: '',
				customization: { color: BrandColors['status-shade-light-3'] },
			},
		];
	}

	return { points, hasCampaign };
};

interface IPriceRightTextParsed {
	config: IGetCoreDetailPoints;
	data: IServicePrices | null;
	end?: string | null;
	installation?: ICustomerInstallation;
}

const _getPriceRightTextParsed = (props: IPriceRightTextParsed) => {
	const { config, data, end, installation } = props;
	const { translations, addonStates } = config;

	let rightText: string = '';
	let campaignEnd: string | undefined = end ?? undefined;
	let futureTextRight: string = '';
	let futurePriceStarts: string | undefined = undefined;
	let status: 'ACTIVE' | 'INACTIVE' = getClampedStatusFromInstallation({
		installationOnly: translations.orderMethodType === 'installation',
		translations,
		installation,
		addonData: addonStates,
	});

	if (status === 'ACTIVE' && data?.customerPrices && data.customerPrices.length > 0) {
		data.customerPrices.forEach((p: ICustomerProductPrice) => {
			rightText += `${tNumber(_getParsedCustomerPointPrice(p, end), 'no-NO')} ${p.denomination}\n`;

			let { text, from } = _getFuturePriceTexts(p.futurePrices, p.denomination);

			futureTextRight += text;
			futurePriceStarts = from;
		});
	} else {
		data?.productPrices?.forEach((p: IProductPrice) => {
			rightText += `${tNumber(p.vatInclusivePrice, 'no-NO')} ${p.denomination}\n`;

			let { text, from } = _getFuturePriceTexts(p.futurePrices, p.denomination);

			futureTextRight += text;
			futurePriceStarts = from;
		});
	}

	return { rightText, campaignEnd, futureTextRight, futurePriceStarts };
};

const _getParsedCustomerPointPrice = (priceData: ICustomerProductPrice, ends?: string | null) => {
	if (ends) {
		return priceData.vatInclusiveDiscountedPrice;
	} else {
		return priceData.vatInclusivePrice;
	}
};

const _getFuturePriceTexts = (futurePrices: IFuturePrice[], denomination: string) => {
	let text: string = '';
	let from: string = '';

	if (futurePrices && futurePrices.length > 0) {
		futurePrices.forEach((fp: IFuturePrice) => {
			text += `${tNumber(fp.vatInclusivePrice, 'no-NO')} ${denomination}\n`;
			from = fp.from;
		});
	}

	return { text, from };
};

const _getAdditionalPointsFromEpi = (infoBlock: any) => {
	let points: ICardPoint[] = [];

	if (infoBlock?.dataPoints && infoBlock.dataPoints.length > 0) {
		infoBlock.dataPoints.forEach((point: IDataPoint) => {
			if (point.key !== 'title') {
				let rightText: string =
					point.key === 'text' ? `${point.amountSuffix}` : `${point.amount} ${point.amountSuffix}`;

				points.push({
					leftText: point.name,
					rightText,
				});
			}
		});
	}

	return points;
};

const _getCostPoints = (
	servicePage: IServicePage,
	right: {
		rightText: string;
		futureTextRight: string;
		campaignEnd?: string;
		futurePriceStarts?: string;
	},
	isSub: boolean = false
) => {
	const { rightText, futureTextRight, campaignEnd, futurePriceStarts } = right;
	let points: ICardPoint[] = [];

	// Campaign
	if (campaignEnd) {
		let campaignEndsDate: Date = new Date(campaignEnd);

		points.push({
			subDetail: isSub,
			leftText: getText('campaignPrice', servicePage),
			rightText,
			extraLeftText: createString(getText('campaignPriceEnds', servicePage), {
				date: format(campaignEndsDate, 'dd.MM.yyyy'),
			}),
		} as ICardPoint);
	}

	// Future price
	if (futurePriceStarts) {
		let futurePriceStartsDate: Date = new Date(futurePriceStarts);

		points.push({
			subDetail: isSub,
			leftText: getText('futurePrice', servicePage),
			rightText: futureTextRight,
			extraLeftText: createString(getText('futurePriceStarts', servicePage), {
				date: format(futurePriceStartsDate, 'dd.MM.yyyy'),
			}),
		} as ICardPoint);
	}

	// General price
	if (rightText.length > 0 && !campaignEnd) {
		points.push({
			subDetail: isSub,
			leftText: getText('cost', servicePage),
			rightText,
		} as ICardPoint);
	}

	return points;
};

export const pointOutStateDescription = (desc: IServiceStatusDescriptions, status: ServiceStatus) => {
	switch (status) {
		case 'ACTIVE':
		case 'TERMINATING':
		case 'ACTIVE_FUTURE':
			return desc.status === 'subscribed' ? desc : undefined;
		case 'INACTIVE':
		case 'TERMINATED':
		case 'ORDER_CANCELLED_BY_CUSTOMER':
		case 'ORDER_IN_PROGRESS':
		case 'ORDER_WAITING_FOR_CUSTOMER':
		case 'ACTIVATING':
			return desc.status === 'unsubscribed' ? desc : undefined;
	}
};
