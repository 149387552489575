import { useContext, createContext } from 'react';

interface IBenefitContext {
	hasOnboarded: boolean | undefined;
	setHasOnboarded: (hasOnboarded: boolean) => void;
}

export const BenefitContext = createContext<IBenefitContext>({
	hasOnboarded: false,
	setHasOnboarded: () => {},
});

export const useBenefitContext = () => useContext(BenefitContext);
