import { ICustomer, ICustomerAccountInformation, ICustomerInvoice, IDefaultProps } from '../../../models';
import { Constants } from '../../../data';
import { IMSPlankWall } from '../../../blocks';
import { getPlankPrefab } from '../../../Prefabs';
import { fetchParsedInvoices, getCorrectInvoicePeriodDate, getText } from '../../../services';
import { BrandColors } from '@fjordkraft/fjordkraft.component.library';

export interface IInvoiceHistoryPageData extends IDefaultProps {
	setShowAccountSelectMenu: (state: boolean) => void;
	activeAccount?: ICustomerAccountInformation;
}

// ************************************
// Main (public)
// ************************************

export const getPageContent = async (config: IInvoiceHistoryPageData) => {
	const { translations, services, setShowAccountSelectMenu, user, activeAccount, desktopView } = config;
	const { userData } = user;
	const { GET } = services;

	if (translations && GET !== undefined && userData) {
		let invoiceData = await fetchParsedInvoices({
			GET,
			userData,
			accountId: activeAccount?.accountId,
			steddiAccountId: activeAccount?.steddiInfo?.beasAccountId,
			filter: activeAccount?.accountId ? 'Account' : 'All',
			hidePaid: false,
		});
		let parsedData: IMSPlankWall[] = [];

		if (invoiceData.invoices.length > 0) {
			parsedData = parseData({
				invoices: invoiceData.invoices,
				translations,
				userData,
				setShowAccountSelectMenu,
				activeAccount,
				desktopView,
			});
		}

		return {
			...config,
			sub: {
				title: getText('historyPageTitle', translations),
				back: {
					text: getText('historyBack', translations),
					link: Constants.paths.invoicePage,
				},
				subTitle: undefined,
				statusToast: undefined,
			},
			invoiceHistory: parsedData,
		};
	}
};

// ************************************
// Functionality (public)
// ************************************

interface IHistoryPlanksConfiguration {
	invoices: ICustomerInvoice[];
	translations: any;
	setShowAccountSelectMenu: (state: boolean) => void;
	userData: ICustomer;
	activeAccount?: ICustomerAccountInformation;
	desktopView: boolean;
}

export const parseData = (config: IHistoryPlanksConfiguration) => {
	const { invoices, translations, desktopView } = config;

	let parsedInvoices: IMSPlankWall[] = [];

	if (invoices && invoices.length > 0 && translations) {
		invoices.forEach((invoice: ICustomerInvoice) => {
			let date = getCorrectInvoicePeriodDate(invoice.invoicePeriod);

			let foundList = _getPlankListBasedOnCategory(
				parsedInvoices,
				_isNotPaid(invoice) ? getText('notPaid', translations) : `${date.getFullYear()}`
			);

			if (foundList.new) {
				parsedInvoices.push(foundList.list);
			}

			foundList.list.planks.push(
				getPlankPrefab('Invoice', {
					invoice,
					translation: translations,
					action: {
						link: `${Constants.paths.invoiceHistoryPage}/${invoice.invoiceNumber}`,
					},
					desktopView,
				})
			);
		});
	}

	return parsedInvoices;
};

const _getPlankListBasedOnCategory = (lists: IMSPlankWall[], compareString: string) => {
	let newList: boolean = true;
	let foundList: IMSPlankWall = {
		title: compareString,
		planks: [],
		customization: {
			titleGap: 1,
			backgroundColor: {
				Light: BrandColors['background-shade-light-2'],
				Dark: BrandColors['background-shade-dark-3'],
			},
		},
		titleCustomization: {
			weight: 400,
			size: 'regular',
		},
	};

	if (lists.length > 0) {
		lists.forEach((list: IMSPlankWall) => {
			if (list.title === compareString) {
				foundList = list;
				newList = false;
			}
		});
	}

	return { list: foundList, new: newList };
};

// ************************************
// Helpers (private)
// ************************************

const _isNotPaid = (invoice: ICustomerInvoice) => {
	switch (invoice.paymentStatus) {
		case 'Overdue':
		case 'PartlyPaid':
		case 'Unpaid':
			return true;
		default:
			return false;
	}
};