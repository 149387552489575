import { useContext, createContext } from 'react';

interface IAuthentication {
    previousPageUrl: string;
    setPreviousPageUrl: (url: string) => void;
}

export const AuthenticationContext = createContext<IAuthentication>({
    previousPageUrl: 'Light',
    setPreviousPageUrl: () => {},
});

export const useAuthenticationContext = () => useContext(AuthenticationContext);
