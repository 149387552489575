import React, { ReactNode, useEffect, useState, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { AuthenticationContext } from '../contexts';
import { ErrorModal } from '../modals/ErrorModal/ErrorModal';
import { Constants } from '../data';
import { User } from 'oidc-client-ts';
import { isImpersonating } from '../services';
import { AuthenticationLoadingModal } from '../modals';

export default function AuthenticationGuard(props: any) {
	// ************************************
	// Properties
	// ************************************

	const { children }: { children: ReactNode | undefined } = props;
	const { isLoading, isAuthenticated, error, signinRedirect, activeNavigator, events, startSilentRenew, user } =
		useAuth();

	// ************************************
	// Lifecycle
	// ************************************

	const [triedToNavigate, setTriedToNavigate] = useState(false);
	const [previousPageUrl, setPreviousPageUrl] = useState<string>('/');

	//Renew access_token when it is about to expire.
	useEffect(() => {
		return events.addAccessTokenExpiring(() => startSilentRenew());
	}, [events, startSilentRenew]);

	useEffect(() => {
		if (triedToNavigate) {
			signinRedirect({
				state: {
					redirectUrl: window.location.href,
				},
			});
		}
	}, [triedToNavigate]);

	const contextHandler = useMemo(() => {
		return {
			previousPageUrl,
			setPreviousPageUrl,
		};
	}, [previousPageUrl]);

	// ************************************
	// Helpers
	// ************************************

	const getRelationShips = (user:User | undefined | null) => user?.profile?.relationships as string[];
	const checkAuthorized = (user: User | undefined | null) => {
		if (user?.profile?.authenticationScheme !== 'B2C') {
			//This is not B2C, no need to authorize
			return true;
		}
		if (!user?.profile?.relationships) {
			return false;
		}
		const brand = process.env.REACT_APP_API_BRAND?.toLowerCase();
		const cutoff = new Date();
		cutoff.setMonth(cutoff.getMonth() - 3);
		const relationships = getRelationShips(user);
		return relationships.some((r) => {
			const arr = r.split(';');
			return r.includes(`;${brand};`) && (!arr[4] || new Date(arr[4]) > cutoff);
		});
	};

	const loginParams = ['fkId', 'li', 'sub'];
	const url = new URL(window.location.href);

	const hasLoginParamInUrl = () => {
		return loginParams.some((lp) => url.searchParams.get(lp));
	};

	const handleLoginParamWhenAlreadyLoggedIn = () => {
		if (!isImpersonating(user) && user?.profile?.sub && user.profile.sub === url.searchParams.get('sub')) {
			loginParams.forEach((lp) => url.searchParams.delete(lp));
			window.location.href = url.toString();
			return;
		} else if (!activeNavigator && !triedToNavigate) {
			_trySignin();
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderErrorModal = (description: string) => {
		return (
			<ErrorModal
				text={'Ooops...'}
				description={description}
				action={{
					text: 'Prøv igjen',
					link: previousPageUrl,
				}}
				logOutButtonText={'Logg ut'}
				brand={Constants.uiBrand}
			/>
		);
	};

	const _trySignin = () => {
		setTriedToNavigate(true);
	};

	// ************************************
	// Authentication Barrier
	// ************************************

	if (isLoading || activeNavigator) {
		return (
			<AuthenticationLoadingModal
				title="Autentiserer bruker"
				subTitle="Vent litt"
				brand={Constants.uiBrand}
				theme={'Light'}
			/>
		);
	}

	if (!isAuthenticated) {
		if (error) {
			if (error.message.includes('AADB2C90157')) {
				return _renderErrorModal('Du tastet inn feil informasjon for mange ganger.');
			}
			if (error.message.includes('noUserFound')) {
				return _renderErrorModal('Vi fant ingen brukere med informasjonen du oppga.');
			}
			if (error.message.includes('AADB2C90273') || error.message.includes('AADB2C90091')) {
				return _renderErrorModal('Avbrøt du innloggingen?');
			}
			return _renderErrorModal(`Ser ut som noe gikk galt, error: ${error.name}`);
		} else if (!activeNavigator && !triedToNavigate) {
			_trySignin();
			return null;
		} else if (!isLoading) {
			return _renderErrorModal('Ser ut som noe gikk galt med autentisering');
		}
	}

	if (hasLoginParamInUrl()) {
		handleLoginParamWhenAlreadyLoggedIn();
		return null;
	}

	if (!checkAuthorized(user)) {
		return (
			<ErrorModal
				text={'Er du på rett sted?'}
				description={`Vi fant ingen aktive kundeforhold for ${process.env.REACT_APP_COMPANYNAME}`}
				logOutButtonText={'Logg ut'}
				brand={Constants.uiBrand}
			/>
		);
	}

	if (!isImpersonating(user)) {
		//User is authenticated. Set CALL cookie for use in the brand domain:
		//Change domain code if on top level domains with two segments (ie *.co.uk)
		const setCookie = (name: string) => document.cookie = `${name}=${new Date()
			.toLocaleString('en-US', {
				hour12: false,
				day: '2-digit',
				month: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				year: 'numeric',
			})
			.replace(',', '')}; expires=${new Date(
			new Date().getTime() + 1000 * 60 * 60 * 24 * 365
		).toUTCString()}; path=/; domain=${window.location.host.split('.').slice(-2).join('.')}; secure`;

		setCookie("CALL");
		if(getRelationShips(user).some(r=>r.toLowerCase().includes(";sparkle;"))){
			//User is also mobile user. sett CALLMOBIL cookie for use in the brand domain:
			setCookie("CALLMOBIL");
		}
	}
	
	if(process.env.REACT_APP_KINDLY_INCLUDE == 'true' && window.kindlyGotAuthToken){
		const url = `${process.env.REACT_APP_API_URL}/v1/${process.env.REACT_APP_API_BRAND}/Customers/kindlyJwt`;
		window.kindlyGotAuthToken.callToResolveWithToken(user?.access_token, url);
	}

	// ************************************
	// Render
	// ************************************

	return (
		<AuthenticationContext.Provider value={contextHandler}>
			{isAuthenticated && children}
		</AuthenticationContext.Provider>
	);
}
