import React from 'react';
import {
    IAction,
    IComponent,
    IComponentTemplate,
} from '@fjordkraft/fjordkraft.component.library';
import { ActionButton } from '../ActionButton/ActionButton';
import { MS_ButtonTemplate } from '../../../Prefabs';
import { useLocation, useNavigate } from 'react-router';
import { isExternalUrl } from '../../../services';

export interface IMenuButton extends IComponent {
    action: IAction;
    template?: IComponentTemplate;
    navigating: (path: string) => void;
}

export const MenuButton = (props: IMenuButton) => {
    // ************************************
    // Properties
    // ************************************

    const { brand, theme = 'Light', action, navigating, template = MS_ButtonTemplate(theme, 'menu') } = props;
    const { pathname } = useLocation();
    const navigate = useNavigate();

    // ************************************
    // Action handling
    // ************************************

    const _handleAction = () => {
        if (action?.link && !isExternalUrl(action.link)) {
            return {
                ...action,
                ...({
                    icon: undefined,
                    onClick: (e) => {
                        let link: string = action.link ?? '/';
                        
                        if(link !== pathname) {
                            navigating(link);
                            navigate(link);
                        } else {
                            window.location.reload();
                        }
                    },
                    link: undefined,
                    overrideTagEnforcer: true,
                } as IAction),
            };
        } else {
            return action;
        }
    };

    // ************************************
    // Render
    // ************************************

    return (
        <ActionButton
            template={template}
            brand={brand}
            disabled={action.disabled}
            action={_handleAction()}
        />
    );
};
