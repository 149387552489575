import React, { useMemo, useState } from 'react';
import {
    getCounterTheme,
    StyleGrid,
} from '@fjordkraft/fjordkraft.component.library';
import {
    useBenefitContext,
    useDefaultPageContext,
    useSubPageLayoutContext,
} from '../../../contexts';
import { getPageContent } from './BenefitsIntroductionPageData';
import { FullPageStory } from '../../../components/FullPageStory/FullPageStory';
import { Constants } from '../../../data';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import './BenefitsIntroductionPage.scss';

export interface IBenefitsIntroductionPage extends IDefaultViewProps {
    benefitIntroductionData: any;
    points: number;
}

export const BenefitsIntroductionPage = () => {
    // ************************************
    // Properties
    // ************************************

    const { setInfoBoxContent } = useSubPageLayoutContext();
    const { setMainContentClamping } = useDefaultPageContext();
    const { setHasOnboarded } = useBenefitContext();
    const classPrefix = 'benefits-introduction-page';

    // ************************************
    // Lifecycle
    // ************************************

    const [benefitIntroductionData, setBenefitIntroductionData] = useState<any>();

    // ************************************
    // Render Functionality
    // ************************************

    const renderFullPageStory = useMemo(() => {
        if (
            benefitIntroductionData?.pages &&
            benefitIntroductionData?.pages.length > 0
        ) {
            return <FullPageStory {...benefitIntroductionData} />;
        }
    }, [benefitIntroductionData]);

    // ************************************
    // Render
    // ************************************

    return (
        PageV2({
            setup: {
                pageType: Constants.epiServerPageNames.benefits.type,
                usesSubPage: true
            },
            startedRender: () => {
                setHasOnboarded(true);
            },
            renderUpdate: () => {
                setMainContentClamping('stretch');
            },
            handleData: getPageContent,
            onDataChange: (config: IBenefitsIntroductionPage) => {
                setBenefitIntroductionData(config.benefitIntroductionData);
                setInfoBoxContent(null);
            },
            render: (config: IBenefitsIntroductionPage) => {
                const { activeBrand, activeTheme } = config;

                return (
                    <StyleGrid
                        className={`${classPrefix}`}
                        alignment={'center'}
                        direction={'row'}
                        boxSizing='border-box'
                        brand={activeBrand}
                        theme={getCounterTheme(activeTheme)}
                    >
                        {renderFullPageStory}
                    </StyleGrid>
                );
            }
        })
    );
};
